export const items = [
    {
        id: 1,
        item: "Home",
        offset: 0
    },
    {
        id: 2,
        item: "About",
        offset: 0
    },
    {
        id: 3,
        item: "Work",
        offset: 0
    },
    {
        id: 4,
        item: "Skills",
        offset: 0
    },
    {
        id: 5,
        item: "Contact",
        offset: 0
    }
]
